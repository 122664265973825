import { ChevronLeftIcon } from '@heroicons/react/outline';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import Layout from '../../components/layout/layout';
import { Headline } from '../../components/layout/typography';

const LaunchpadPage = () => {
  return (
    <Layout
      title="Founders' Shortcut"
      description="Get the full data treatment with the Founders' Shortcut"
    >
      <section id="solution" className="pt-32 bg-white overflow-hidden">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center">
            <div>
              <nav aria-label="Back">
                <a
                  href="/services"
                  className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  <ChevronLeftIcon
                    className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Back
                </a>
              </nav>
            </div>
          </div>
          <div className="py-12 w-full flex flex-col items-center">
            <p className="block text-base text-center font-semibold tracking-wide uppercase">
              Get the full data treatment with the
            </p>
            <Headline className="mt-2 block text-5xl text-center font-extrabold tracking-tight text-6xl sm:text-4xl tracking-tight font-extrabold lg:text-5xl xl:text-6xl">
              Founders&apos; <br />
              Shortcut
            </Headline>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <StaticImage
                      className="w-full"
                      src="../../images/shortcut.png"
                      alt="A picture of the Founders' Shortcut services package"
                      placeholder="blurred"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="mt-6 prose prose-red prose-lg text-gray-500 pb-8">
                <h2>Because time is your most valuable resource.</h2>
              </div>
              <div className="text-lg text-gray-500 max-w-prose mx-auto lg:max-w-none">
                <p className="mb-4">
                  You've understood that cold hard data is necessary to make the
                  right decisions for your startup's success. But experiments
                  are hard and time-consuming. I got you covered: You provide
                  your idea, I'll do the rest.
                </p>
                <p>All sessions can be done remotely or face-to-face.</p>
              </div>
              <div className="mt-5 prose prose-red text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <h3>That&apos;s in it for you</h3>
                <ul role="list">
                  <li>
                    A Kick-Off Workshop (60 min.) where we dive deep into your
                    startup. I will create a Lean Canvas for you and we will
                    identify the assumptions that make or break your business.
                  </li>
                  <li>
                    Afterwards I will design, execute and evaluate 3 iterations
                    of experiments to test those assumptions and keep you in the
                    loop.
                  </li>
                  <li>This will take place over the course of 3 weeks.</li>
                  <li>
                    After those iterations I will create detailed reports
                    containing all decisive metrics and proposals for next
                    steps&mdash;based on real data!
                  </li>
                  <li>
                    You will receive the report and proposals along with a brief
                    presentation of 30 minutes.
                  </li>
                </ul>
                <h3>What you need to provide</h3>
                <ul role="list">
                  <li>Your idea, ideally written down on a Lean Canvas</li>
                  <li>
                    Two time slots: One of 60 minutes and one of 30 minutes 3
                    weeks later.
                  </li>
                  <li>I will take care of the rest for you.</li>
                </ul>
                <p className="text-4xl font-extrabold text-gray-900">
                  Book now for 2549€
                </p>
              </div>
              <div className="mt-10 flex text-base max-w-prose mx-auto lg:max-w-none">
                <div className="rounded-md shadow">
                  <a
                    href="mailto:hello@timothy.de?subject=Give%20me%20the%20data%20I%20need%20with%20the%20Founders'%20Shortcut%2C%20please!&body=Hey%20Timothy%2C%0D%0A%0D%0AI%20am%20interested%20in%20the%20Founders'%20Shortcut.%20Can%20we%20arrange%20a%20call%3F%0D%0A%0D%0ACheers%2C%0D%0A"
                    className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-timothy-purple hover:bg-purple-900"
                  >
                    I&apos;m interested!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default LaunchpadPage;

const Info = () => (
  <section id="hero" className="lg:h-screen-4/5 w-full flex flex-wrap">
    <div className="w-full lg:w-1/2 h-full pt-32 px-2 lg:px-8 flex items-end lg:items-center lg:justify-end">
      <div className="w-full lg:w-auto text-center lg:text-left z-10">
        <Headline className="text-6xl sm:text-4xl tracking-tight font-extrabold lg:text-5xl xl:text-6xl">
          Timothy
          <br />
          Krechel
        </Headline>
      </div>
    </div>
  </section>
);
